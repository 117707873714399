import {
    createRouter,
    createWebHistory
} from "vue-router";
import storage from '../utils/storage'
import {
    ElMessage
} from "element-plus";

const index = () => import("../views/Index.vue")
const login = () => import("../views/Login.vue")
const signup = () => import("../views/Signup.vue")
const teacherHome = () => import("../views/TeacherHome.vue")
const adminHome = () => import("../views/AdminHome.vue")
const studentHome = () => import("../views/StudentHome.vue")
const therapistHome = () => import("../views/TherapistHome.vue")

const routes = [{
        path: "/",
        redirect: "/index"
    },
    {
        path: "/index",
        name: "index",
        component: index
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/signup/:userType",
        name: "signup",
        component: signup
    },
    {
        path: "/teacherHome/:userId",
        name: "teacherHome",
        component: teacherHome,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/adminHome/:userId",
        name: "adminHome",
        component: adminHome,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/studentHome/:userId",
        name: "studentHome",
        component: studentHome,
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/therapistHome/:userId",
        name: "therapistHome",
        component: therapistHome,
        meta: {
            requireAuth: true
        }
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes: routes
})


// check token for the page required, or back to login page
router.beforeEach((to, from, next) => {
    // Only when requiresAuth is false, no login is required. Others require
    // to.matched.some(r => r.meta.requireAuth) or to.meta.requiresAuth
    if (to.matched.some(r => r.meta.requireAuth) !== false) {
        let token = storage.get('TOKEN')
        if (token) {
            console.log("valid token")
            next();

        } else {
            ElMessage({
                message: "No valid token, please login",
                type: "error",
            });
            storage.remove('TOKEN')
            next({
                path: "/login",
            });
        }

    } else {
        next();
    }
});


export default router